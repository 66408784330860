import { useState, useEffect } from 'react'
import {
    Box,
    Center,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Button,
} from '@chakra-ui/react'
import { FiSearch, FiDownload } from 'react-icons/fi'
import { IoArrowUp, IoArrowDown } from 'react-icons/io5'
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Pagination } from './Pagination'
import { useNavigate } from 'react-router-dom'

// data and columns MUST be memoized (see useMemo)
export const DataTable = ({
    data,
    columns,
    title,
    description,
    sortByField,
    sortDesc = true,
    pageSize = 30,
    enableSearch = true,
    enableRowNavigation = true,
    navigationUrlPrefix = 'details/',
    navigationIdField = 'id',
    noDataText = 'No data was found',
    hiddenColumns = [] }) => {

    const navigate = useNavigate()

    const exportToCSV = () => {
        // Get visible columns (excluding hidden ones)
        const visibleColumns = columns.filter(col => !hiddenColumns.includes(col.id || col.accessor))

        // Create header row
        const headers = visibleColumns.map(col => col.Header).filter(header => header !== '').join(',')

        // Sort data by name and create data rows
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name))
        const csvRows = sortedData.map(row => {
            return visibleColumns
                .map(col => {
                    const value = typeof col.accessor === 'function'
                        ? col.accessor(row)
                        : row[col.accessor]
                    return value !== undefined ? `"${value}"` : ''
                })
                .filter(value => value !== '')
                .join(',')
        })

        // Combine headers and rows
        const csvContent = [headers, ...csvRows].join('\n')

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'apps.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const tbl = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: sortByField,
                        desc: sortDesc
                    }
                ],
                pageSize: pageSize,
                hiddenColumns: hiddenColumns,
            },
            // react-table allows sorting in ascending order, descending order, or no order (whatever order the data is given in)
            // disableSortRemove disallows the "no order" option as it's not very useful.
            disableSortRemove: true,
        },
        useGlobalFilter,
        useSortBy,
        usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, globalFilter },
        setGlobalFilter,
    } = tbl

    useEffect(() => {
        setPageSize(pageSize)
    }, [pageSize, setPageSize])

    const [search, setSearch] = useState(globalFilter)
    const onSearchChange = useAsyncDebounce(search => {
        setGlobalFilter(search || undefined)
    }, 200)

    const getRowNavigationProps = (id) => {
        if (enableRowNavigation) {
            let prefix = navigationUrlPrefix
            if (!prefix.endsWith("/")) {
                prefix = prefix + "/"
            }
            const to = prefix + id
            return { cursor: 'pointer', onClick: () => navigate(to) }
        }
        return {}
    }

    return (
        <Stack spacing="5">
            {(title || description || enableSearch) && (
                <Box
                    px={{ base: '4', md: '6' }}
                    pt="5"
                >
                    <Stack
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        justify="space-between"
                    >
                        <Text fontSize="2xl" fontWeight="medium">
                            {title}
                        </Text>
                        <HStack spacing="4">
                            {enableSearch && (
                                <InputGroup colorScheme='gray' maxW="xs">
                                    <InputLeftElement pointerEvents="none">
                                        <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                                    </InputLeftElement>
                                    <Input colorScheme='gray' placeholder="Search" value={search || ""} onChange={e => {
                                        setSearch(e.target.value)
                                        onSearchChange(e.target.value)
                                    }} />
                                </InputGroup>
                            )}
                            <Button
                                leftIcon={<FiDownload />}
                                size="md"
                                onClick={exportToCSV}
                                variant="solid"
                                px={8}
                            >
                                Export CSV
                            </Button>
                        </HStack>
                    </Stack>
                    {description && (
                        <Box my="5">
                            {typeof description === 'string' ? (
                                <Text>{description}</Text>
                            ) : (
                                description
                            )}
                        </Box>
                    )}
                </Box>
            )}
            <Box overflowX="auto">
                <Pagination
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                />

                <Table {...getTableProps()}>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <HStack spacing="3">
                                            <>{column.render('Header')}</>
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
                                                    :
                                                    <Icon as={IoArrowUp} color="fg.muted" boxSize="4" />
                                                : ''}
                                        </HStack>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                                <Tr {...row.getRowProps()} {...getRowNavigationProps(row.original[navigationIdField])}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Td whiteSpace="normal" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                {page.length === 0 && (
                    <Center m="10" textColor="fg.subtle">{noDataText}</Center>
                )}
            </Box>

            <Pagination
                pageIndex={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
            />
        </Stack>
    )
}
