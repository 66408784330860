import { useMemo, useState } from 'react'
import {
  Box,
  Icon, Stack, Tag, Text,
  RadioGroup,
  Radio,
  HStack,
  Select,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useGetAppsQuery } from '../../apis/org-chart'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { DataTable } from '../../components/DataTable'
import { AppTypeTag } from '../../components/AppTypeTag'
import { useColorSchemes } from "../../utils/color";

export const Apps = () => {
  const { data: apps = [], isLoading: isLoadingApps } = useGetAppsQuery({})
  const [filter, setFilter] = useState('all') // 'all', 'helm', or 'fd'
  const [pageSize, setPageSize] = useState(10)

  const filteredApps = useMemo(() => {
    switch (filter) {
      case 'helm':
        return apps.filter(app => app.helm_chart)
      case 'fd':
        return apps.filter(app => !app.helm_chart)
      default:
        return apps
    }
  }, [apps, filter])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Namespace',
        accessor: 'namespace',
      },
      {
        Header: 'Type',
        accessor: (row, _) => row.helm_chart ? 'helm' : 'fd',
        Cell: ({ row }) => (
          <AppTypeTag isHelmChart={row.original.helm_chart} />
        ),
        id: 'type',
      },
      {
        Header: 'Clusters',
        accessor: 'clusters',
        Cell: ({ value }) => value.join(', '),
      },
      {
        Header: '',
        Cell: () => <Icon as={FiChevronRight} />,
        id: 'details_link',
      }
    ],
    [])

  // title + badges
  const { totalAppsColorScheme, helmChartColorScheme, fdColorScheme } = useColorSchemes();

  const title = (
    <Box>
      <Text fontSize='2xl' fontWeight='medium'>Apps</Text>
      <Stack direction='row' spacing='2'>
        <Tag mt='2' fontSize='0.6em' colorScheme={totalAppsColorScheme}>Total Apps: {apps.length}</Tag>
        <Tag mt='2' fontSize='0.6em' colorScheme={helmChartColorScheme}>Helm Chart: {apps.filter(app => app.helm_chart).length} ({((apps.filter(app => app.helm_chart).length / apps.length) * 100).toFixed(1)}%)</Tag>
        <Tag mt='2' fontSize='0.6em' colorScheme={fdColorScheme}>FD: {apps.filter(app => !app.helm_chart).length} ({((apps.filter(app => !app.helm_chart).length / apps.length) * 100).toFixed(1)}%)</Tag>
      </Stack>
      <HStack spacing='8' mt='4'>
        <RadioGroup onChange={setFilter} value={filter}>
          <HStack spacing='4'>
            <Radio value='all' size='sm'>All Apps</Radio>
            <Radio value='helm' size='sm'>Helm Charts Only</Radio>
            <Radio value='fd' size='sm'>FD Only</Radio>
          </HStack>
        </RadioGroup>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          width='200px'
          size='sm'
          rounded='md'
        >
          <option value={10}>10 per page</option>
          <option value={25}>25 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={-1}>Show all</option>
        </Select>
      </HStack>
    </Box>
  )

  return (
    isLoadingApps ? (
      <LoadingSpinner />
    ) : (
      <DataTable
        data={filteredApps}
        columns={columns}
        title={title}
        sortByField='name'
        sortDesc={false}
        pageSize={pageSize === -1 ? filteredApps.length : pageSize}
      />
    )
  )
}
